import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.titles[_ctx.operaType],
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        "label-position": _ctx.$formLabelPosition,
        rules: _ctx.rules,
        model: _ctx.formData,
        ref: "form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPersonDeviceInHtmlOwner
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Node,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Node = $event)),
                disabled: true
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPersonDeviceInHtmlMAC,
            prop: "MAC"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.MAC,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.MAC = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPersonDeviceInHtmlLocation,
            prop: "Location"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Location,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.Location = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label-position", "rules", "model"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}