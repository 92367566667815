import { ref, Ref, computed } from 'vue';
import { community } from '@/data';
import { useRoute } from 'vue-router';

interface FormData {
    Node: string;
    MAC: string;
    DeviceType: string;
    Location: string;
    Relay: string;
    SecurityRelay: string;
    isPublic: string;
    Type: string;
    Builds: Array<string> | string;
    IsAllBuild: number | boolean;
    Build?: string;
    ArmingFunction: string;
    Special: string;
    NetGroupNumber: string;
    NodeID: string;
    StairShow: string;
}

type IdentityType = 'single' | 'community' | 'office';

const deviceType = [{
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeStair,
    value: '0'
}, {
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeDoor,
    value: '1'
}, {
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor,
    value: '2'
}, {
    label: WordList.ProperAllTextAuditCategoryAccess,
    value: '50'
}];
const comDeviceType = [{
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeStair,
    value: '0'
}, {
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeDoor,
    value: '1'
}, {
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor,
    value: '2'
}, {
    label: WordList.SelectManagementPhone,
    value: '3'
}, {
    label: WordList.ProperAllTextAuditCategoryAccess,
    value: '50'
}];

const createNetwork = () => {
    const networkOptions: Ref<Array<string>> = ref([]);
    const networkLength = 20;
    for (let i = 0; i < networkLength; i += 1) {
        networkOptions.value.push(`${i}`);
    }
    return networkOptions;
};

interface ComBuildType {
    UnitName: string;
    Editable: boolean;
    ID: string;
}
interface ChooseBuildType {
    key: string;
    label: string;
}
const chooseBuildsFunc = (isAllBuild: number | boolean | string, builds: Array<string> | string, identity: string) => {
    const chooseBuilds: Ref<Array<ChooseBuildType>> = ref([]);
    const unchooseBuilds: Ref<Array<ChooseBuildType>> = ref([]);

    const getBuilds = () => {
        chooseBuilds.value = [];
        unchooseBuilds.value = [];
        community.getCommunityBuild((res: {
            data: Array<ComBuildType>;
        }) => {
            if (isAllBuild === 1) {
                res.data.forEach((item) => {
                    chooseBuilds.value.push({
                        key: item.ID,
                        label: item.UnitName
                    });
                });
            } else {
                res.data.forEach((item) => {
                    if (builds && !builds.includes(item.ID)) {
                        unchooseBuilds.value.push({
                            key: item.ID,
                            label: item.UnitName
                        });
                    } else {
                        chooseBuilds.value.push({
                            key: item.ID,
                            label: item.UnitName
                        });
                    }
                });
            }
        });
    };
    if (identity === 'community' || identity === 'office') {
        getBuilds();
    }

    return {
        chooseBuilds,
        unchooseBuilds,
        getBuilds
    };
};

export default null;
export {
    FormData,
    deviceType,
    IdentityType,
    comDeviceType,
    ChooseBuildType,
    createNetwork,
    chooseBuildsFunc
};