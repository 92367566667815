import http, {
    defaultError, successParam, errorParam
} from '@/util/axios.config';
import * as Type from './all.type';

function GetRequest(
    url: string,
    params: object,
    callback: Function,
    isReturnData = true,
    errorCallback: errorParam = defaultError
) {
    http.get(url, params, (data: Type.GetDataRequest) => {
        if (isReturnData) {
            callback(data.data);
        } else {
            callback(data);
        }
    }, errorCallback);
}

function PostRequest(
    url: string,
    params: object,
    callback: successParam,
    errorCallback: errorParam = defaultError,
    isReturnData = false
) {
    let call = callback;
    let showTips = true;
    let callbackNew: successParam = (data: Type.PostDataRequest) => {
        if (isReturnData) {
            (call as Function)(data.data);
        } else {
            (call as Function)(data);
        }
    };
    if (!(typeof callback === 'function')) {
        [call, showTips] = callback as [Function, boolean];
        callbackNew = [callbackNew, showTips];
    }
    http.post(url, params, callbackNew, errorCallback);
}

export {
    http,
    Type,
    GetRequest,
    PostRequest,
    successParam,
    errorParam
};