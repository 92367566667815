
import {
    defineComponent, PropType, ref, reactive
} from 'vue';
import { checkThirdDeviceMAC } from '@/methods/rules/device';
import { addThirdPartDevice, editThirdPartDevice } from '@/api/single/third-party-device';
import {
    createNetwork
} from '@/components/view/installer/device-set-dialog/all.type';

export interface FormData {
    ID?: string;
    Node?: string;
    NetGroupNumber?: string;
    NodeUUID?: string;
    MAC: string;
    Location: string;
    PersonalAccountUUID: string;
}

const networkOptions = createNetwork();
export default defineComponent({
    emits: ['close', 'success'],
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initData: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.TabelPersonDeviceTitleAdd,
            edit: WordList.TabelPersonDeviceTitleMod
        };

        // 表单相关
        const form = ref();
        const formData = reactive({ ...props.initData });
        const rules = {
            MAC: [{
                required: true,
                message: WordList.RuleMacEmpty,
                trigger: 'blur'
            }, {
                validator: checkThirdDeviceMAC,
                trigger: 'blur'
            }],
            Location: [{
                required: true,
                message: WordList.RuleLocationEmpty,
                trigger: 'blur'
            }]
        };

        // 提交区分身份,操作类型
        function submit() {
            form.value.validate((valid: boolean) => {
                if (valid) {
                    const url = {
                        add: addThirdPartDevice,
                        edit: editThirdPartDevice
                    };
                    url[props.operaType](formData, () => {
                        emit('success');
                        emit('close');
                    });
                }
            });
        }
        return {
            titles,
            form,
            formData,
            rules,
            submit,
            networkOptions
        };
    }
});
